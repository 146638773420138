import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../Interactions';
import PreInteraction from '../../PreInteraction';
import ResultGame from '../../Response/ResultGame';

const CardHandler = props => {
  const {
    g: {
      preinteraction,
      log: { steps },
    },
    played,
  } = props;

  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }

  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction);

  let content = null;

  const onShowResult = () => setShowResult(true);

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };

  content = (
    <React.Fragment>
      {showResult || played ? (
        <ResultGame {...props} />
      ) : !showInteraction && steps.preinteraction.data === null ? (
        <PreInteraction
          preinteraction={preinteraction}
          onShowInteractionHandler={onShowInteractionHandler}
          {...props}
        />
      ) : (
        <Card typeInteraction='interaction' onShowResult={onShowResult} {...props} />
      )}
    </React.Fragment>
  );

  return content;
};

CardHandler.propTypes = {
  action: PropTypes.object,
};

export default CardHandler;
