import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Loader, CountDown, Steps, StartGame } from '../../../Ui';
import Fade from '../../../Fade';
import Answers from './Answers';
import Question from './Question';
import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';
import Dynamic from '../../Dynamics/Dynamic';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { isBetween, getSlug, getElement } from '../../../../utils/utils';
import { FastForward } from 'styled-icons/boxicons-regular';
import { darken } from 'polished';
import ResultGame from '../../Response/ResultGame';
import styledProps from 'styled-props/lib/styledProps';
import Profile from '../../Response/Profile';

const Container = styled.div`
  background-image: ${props => `url(${props.bg})`};
  background-position: center center;
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  /*   width: 100%;
  height: ${({ height }) => height}px; */
  min-height: 360px;
  ${respondTo.sm`
 min-height: 500px;
  `}
`;

const QuizContainer = styled.div`
  margin: 0px auto;
  padding: 0px;
  max-width: 100%;
  padding-bottom: 10px;
  min-height: 640px;
  position: relative;
  box-sizing: border-box;
  ${respondTo.med`
    width: 520px;
    margin: 24px auto;
    padding: 8px;
    max-width: 90%;
    min-height: 640px;
    position: relative;
    box-sizing: border-box;
  `};
  ${respondTo.sm`
    width: 520px;
  `};
  ${respondTo.md`
    width: 520px;
  `};
`;

const SlideContainer = styled.div`
  text-align: center;
`;

const StepsContainer = styled.div``;

const StepsCountContainer = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  margin: 12px auto 0;
  /* justify-content: ${props => (props.isTimer ? 'space-between' : 'flex-end')}; */
  justify-content: center;
`;

const NextContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${props => props.theme.bg_bottoni};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: ${props => props.theme.colore_testo_bottoni || props.theme.default_text_color};
  cursor: pointer;
  & span {
    display: block;
  }
  & svg {
    margin-left: 3px;
  }
  &:hover {
    background-color: ${props => darken(0.1, props.theme.bg_bottoni)};
  }
`;

const SurveyQuiz = props => {
  const {
    lang,
    typeInteraction,
    onShowResult,
    g: {
      interaction,
      preinteraction,
      id,
      log: {
        sid,
        steps: {
          interaction: { data: dataLoose },
        },
      },
    },
    action: { data: dataAction },
  } = props;

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === 'preinteraction') {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  const { id: idGame, type, settings, i18l } = rules || {};
  const {
    [lang]: { results, game },
  } = i18l || { it: {} };
  const {
    instantcheck,
    selectedcheck,
    showtrueanswer,
    max,
    random,
    time,
    timer,
    next,
    steps,
    onlywin,
    aspect,
  } = settings || {};

  const { bggame, colorWin, colorLoose, colorAnswers } = aspect || {};

  const carRef = useRef(null);

  const [cont, setCont] = useState([]);
  const [selected, setSelected] = useState([]);
  const [resultData, setResultData] = useState({});
  const [answers, setAnswers] = useState([]);
  const [index, setIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [startCountDown, setStartCountDown] = useState(false);
  const [startGame, setStartGame] = useState(!timer);
  const [step, setStep] = useState(1);
  const [timeComplete, setTimeCompleted] = useState(false);
  const [pause, setPause] = useState(false);
  const [goToNextByClick, setGoToNextByClick] = useState(false);
  const [action, setAction] = useState(false);

  const nextHandler = () => {
    setCurrentSlide(currentSlide + 1);
    setTimeCompleted(false);
  };

  const changeSlideHandler = () => {
    setTimeout(() => {
      setStartCountDown(true);
      setStep(step + 1);
      setPause(false);
      setGoToNextByClick(false);
    }, 800);
  };

  const startCountDownHandler = () => {
    setStartCountDown(true);
  };

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      setAnswers(prevState => [...prevState, { points: '0' }]);
      setTimeCompleted(true);
      return null;
    } else {
      return (
        <div>
          {seconds} <span>sec</span>
        </div>
      );
    }
  };

  const startGameHandler = () => {
    setStartGame(true);
    startCountDownHandler();
  };

  const updateCurrentSlide = i => {
    if (currentSlide !== i) {
      setCurrentSlide(i);
    }
  };

  const selectAnswerHandler = (e, obj) => {
    setPause(true);
    setAnswers(prevState => [...prevState, obj]);
  };

  const checkFinish = (answers, selected) => {
    if (answers.length > 0) {
      if (answers.length === selected?.length) {
        setTimeout(() => {
          createResult(answers);
        }, 250);
      } else {
        setIndex(index + 1);
        if (next === 'auto') {
          nextHandler();
        }
      }
    }
  };

  const createResult = answers => {
    const totalPoints = answers.reduce(function (prev, current) {
      return prev + parseInt(current.points);
    }, 0);
    if (type === 'quiz') {
      const haveYouWin = answers.filter(a => a.result === '1');
      const rightanswers = { rightanswers: haveYouWin.length.toString() };
      const totalquestions = { totalquestions: answers.length.toString() };
      const haveyouwin = { haveyouwin: haveYouWin };

      const won = haveYouWin.length === selected?.length ? true : false;

      for (const prop in results) {
        // {'3-5':{...}, '1-3':{....}}
        const result = isBetween(
          totalPoints,
          parseInt(prop.split('-')[0]),
          parseInt(prop.split('-')[1]),
        );
        if (result) {
          const data = {
            profile: { ...results[prop] },
            attempts: rightanswers.rightanswers,
            nAttempts: totalquestions.totalquestions,
            onlywin,
            won,
            answers,
          };
          setResultData(data);
        }
      }
    }
    if (type === 'survey') {
      // let p = answers.map(a => a.points);
      // let r = p.reduce(
      //   (b, c) => (
      //     (b[b.findIndex(d => d.el === c)] || b[b.push({ el: c, count: 0 }) - 1]).count++, b
      //   ),
      //   [],
      // );
      // let max = Math.max.apply(
      //   Math,
      //   r.map(o => o.count),
      // );
      // let last = r.filter(r => r.count === max);
      // let el = null;

      // if (last.length > 0) {
      //   if (last.length === 1) {
      //     el = last[0].el;
      //   } else {
      //     let elArr = last.reduce(function (prev, curr) {
      //       return prev.el < curr.el ? prev : curr;
      //     });
      //     el = elArr.el;
      //   }
      // }

      const data = {
        profile: { ...results[totalPoints] },
        answers,
      };

      setResultData(data);
    }
  };

  useEffect(() => {
    init();
    return () => {};
  }, []);

  const init = () => {
    if (random) {
      let shuffled = game.sort(() => 0.5 - Math.random());

      setSelected(shuffled.slice(0, max));
    } else {
      setSelected(game);
    }
  };

  useEffect(() => {
    let d = [];
    if (selected?.length > 0) {
      d = selected.map(el => {
        return (
          <SlideContainer key={el.id}>
            <div>
              <Question media={el.media} pause={pause}>
                {el.text}
              </Question>
              <Answers
                colorAnswers={colorAnswers}
                colorWin={colorWin}
                colorLoose={colorLoose}
                options={el.options}
                id={el.id}
                clicked={selectAnswerHandler}
                instantcheck={instantcheck}
                selectedcheck={selectedcheck}
                showtrueanswer={showtrueanswer}
                isStartDisabled={timer}
                currentSlide={currentSlide}
                completed={timeComplete}
              />
            </div>
          </SlideContainer>
        );
      });
    }

    setCont(d);

    if (timeComplete) {
      setGoToNextByClick(false);
    }
  }, [selected, timeComplete, pause]);

  useEffect(() => {
    setTimeout(() => {
      checkFinish(answers, selected);
    }, 500);

    setStartCountDown(false);
  }, [answers]);

  const nextHandlerFromClick = () => {
    if (next === 'click') {
      setGoToNextByClick(true);
    }
  };

  useEffect(() => {
    if (goToNextByClick) {
      nextHandler();
    } else {
      return;
    }
  }, [goToNextByClick]);

  useEffect(
    () => {
      interaction?.type === 'auto' && setAction(true);
      if (Object.entries(resultData).length !== 0) {
        if (resultData.won) {
          setAction(true);
        } else {
          if (onlywin) {
            onShowResult();
          } else {
            setAction(true);
          }
        }
        // setAction(true);
      }
    },
    [
      /* resultData */
    ],
  );

  useEffect(() => {
    if (dataAction.length > 0) {
      let isData = getElement(dataAction, id);
      if (isData) {
        onShowResult();
      }
    }
  }, [dataAction]);

  const [widthImg, setWidthImg] = useState(0);
  const [heightImg, setHeightImg] = useState(0);
  //recuperer le height et le width de l image pour le metre dans container

  /*   let imgSize = new Image();
  imgSize.src = bggame;

  imgSize.onload = () => {
    setWidthImg(imgSize.width);
    setHeightImg(imgSize.height);
  }; */
  return (
    <React.Fragment>
      {cont.length > 0 ? (
        <Container bg={bggame}>
          {startGame ? (
            <QuizContainer>
              <Fade show={true}>
                <Carousel
                  dynamicHeight={true}
                  selectedItem={currentSlide}
                  // onChange={updateCurrentSlide}
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeable={false}
                  ref={carRef}
                  onChange={changeSlideHandler}
                >
                  {cont}
                </Carousel>
                <StepsCountContainer isTimer={timer}>
                  {timer ? (
                    timeComplete ? (
                      next !== 'auto' ? (
                        <NextContainer onClick={nextHandlerFromClick} disabled={goToNextByClick}>
                          <FastForward size={50} />
                        </NextContainer>
                      ) : null
                    ) : (
                      <CountDown
                        clicked={startCountDownHandler}
                        start={startCountDown}
                        time={time}
                        startgame={startGameHandler}
                        rend={renderer}
                        timeComplete={timeComplete}
                        gotonext={nextHandlerFromClick}
                        goToNextByClick={goToNextByClick}
                      />
                    )
                  ) : null}
                  {steps ? (
                    <StepsContainer>
                      <Steps current={step} total={selected.length} />
                    </StepsContainer>
                  ) : null}
                </StepsCountContainer>
              </Fade>
            </QuizContainer>
          ) : (
            <StartGame clicked={startGameHandler} />
          )}
        </Container>
      ) : (
        <Loader show={true} full />
      )}
      {action ? (
        <Dynamic
          id={id}
          action={props.action}
          slug={slug}
          section={typeInteraction}
          resultData={resultData}
          sid={sid}
          {...props}
        />
      ) : null}
    </React.Fragment>
  );
};

SurveyQuiz.propTypes = {
  option: PropTypes.string,
  id: PropTypes.number,
};

export default SurveyQuiz;
