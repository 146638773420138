import React, { useState } from 'react';
import useForm from '../../../hooks/useForm';
import { Input, Button, Wrapper, Loader, Text, Modal, Col, Box, Flex } from '../../Ui';
import Fade from '../../Fade';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { respondTo } from '../../../theme/mixin';
import { useTranslation } from 'react-i18next';
import customaxios from '../../../config/axios-refresh-token';
import { getSlug } from '../../../utils/utils';
import { connect } from 'react-redux';
import InputField from '../../Ui/Input/InputField/InputField';
import * as actionCreators from '../../../store/actions';
import moment from 'moment';

const Response = styled.div`
  text-align: center;
`;

const Extra = styled.p`
  font-size: 20px;
`;

const Form = props => {
  const {
    form: {
      formId,
      data,
      type,
      i18l: { title, content, extra },
    },
    lang,
    id,
    playslog,
    formResponse,
    played,
    checkFormCompiled,
    checkFormPrizeAccepted,
    buttonAlign,
    userInfo,
    game_cms,
    bgresultcolor,
    setRefuseForm,
    text_color,
  } = props;

  let showResponse = false;
  if (formResponse !== null && played) {
    showResponse = true;
  }

  const [loading, setLoading] = useState(false);
  const [showFormResponse, setShowFormResponse] = useState(showResponse);
  const [showModal, setShowModal] = useState(false);
  const [info, setInfo] = useState('');
  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetImage, setResetImage] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [submitData, setSubmitdata] = useState({});

  const [t] = useTranslation();
  const dataForm = JSON.parse(data);

  let slug = getSlug();

  const setImage = value => {
    setImmagine(value);
    if (!value) {
      setResetImage(true);
    }
  };
  const formSetSelectedFiles = file => {
    setSelectedFiles(prevState => {
      return [...prevState, file];
    });
  };

  const submitHandler = e => {
    const objData = {};
    for (const property in formData) {
      objData[property] = formData[property].value;
    }

    const data = new FormData();

    let fileArray = [];
    let fileExtraArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].name === 'file') {
        fileArray.push(selectedFiles[i]);
      } else if (selectedFiles[i].name === 'fileextra') {
        fileExtraArray.push(selectedFiles[i]);
      }
    }

    fileArray.forEach(function (element) {
      if (fileArray[fileArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });
    fileExtraArray.forEach(function (element) {
      if (fileExtraArray[fileExtraArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });

    data.append('formId', formId);
    data.append('data', JSON.stringify(objData));
    data.append('section', 'form');
    if (type === 'post') {
      data.append('playslog', playslog);
    }
    if (objData.prize === '0' && !submitModal) {
      setSubmitModal(true);
    } else {
      setLoading(true);
      customaxios
        .post(`game/${slug}/${id}/data`, data)
        .then(response => {
          setLoading(false);
          if (response.status === 200) {
            if (response.data.data.status) {
              setShowFormResponse(true);
            } else {
              setShowModal(true);
              setInfo(response.data.data.info);
            }
            if (
              response.data.data &&
              JSON.parse(response.data.data.data) &&
              JSON.parse(response.data.data.data).prize
            ) {
              checkFormPrizeAccepted(JSON.parse(response.data.data.data).prize);
            }
            setSubmitModal(false);
            checkFormCompiled(formId);
            objData.prize === '0' && setRefuseForm(true);
          }
        })
        .catch(error => {
          setLoading(false);
          setShowModal(true);
          setInfo('errore interno');
        });
    }
  };

  const closeModal = () => {
    setInfo('');
    setShowModal(false);
  };

  let datePickerNames = [];

  for (var prop in dataForm) {
    if (dataForm[prop].elementType === 'datepicker') {
      datePickerNames.push(dataForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    setFormValid,
    imgPreviewUrl,
    districts,
    cities,
    zipcode,
    resetFormHandler,
  } = useForm(submitHandler, dataForm, datePickerNames, false, true);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        readonly={inp.readonly}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={datePickerHandler}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        lang={lang}
        inModal={true}
        radioInputs={inp.inputs}
        setImage={setImage}
        setFormValid={setFormValid}
        formSetSelectedFiles={formSetSelectedFiles}
        resetFormHandler={resetFormHandler}
        districts={districts}
        cities={cities}
        zipcode={zipcode}
        {...inp}
      />
    );
  });

  return (
    <>
      {showFormResponse ? (
        <Fade show={true}>
          <Response>
            {Boolean(Number(formData.prize.value)) ? (
              extra && (
                <Text color={text_color} align='center' className='mb-20 mt-20'>
                  {extra /*  || 'Grazie per aver compilato il form' */}
                </Text>
              )
            ) : (
              <Text className='mb-20' color={text_color}>
                La tua decisione di non accettare il premio è stata memorizzata
              </Text>
            )}
            <Flex justify='center'>
              <Link to='/'>
                <Button active className='mt-20'>
                  Torna alla homepage
                </Button>
              </Link>
            </Flex>
          </Response>
        </Fade>
      ) : (
        <CustomFlex row={15} align='stretch' justify='center' className='mt-20'>
          <Col width={50}>
            <Box padding='20px' className='boxFlex'>
              {title && (
                <Text color={text_color} className='mb-20'>
                  {title}
                </Text>
              )}
              {content && (
                <Text color={text_color} className='mb-20'>
                  {content}
                </Text>
              )}

              {inputs}

              <Button disabled={loading} onClick={formSubmitHandler} white active>
                {loading ? <Loader white inBtn show={true} /> : t('auth.send')}
              </Button>
            </Box>
          </Col>
        </CustomFlex>
      )}

      <Modal alert show={showModal} close={closeModal}>
        <p>{info && info}</p>
      </Modal>
      <Modal alert show={submitModal} close={() => setSubmitModal(false)}>
        <Text>Sei sicuro di voler rinunciare al premio vinto?</Text>
        <Flex gap='25px' justify='center' className='mt-20'>
          <Button active onClick={submitHandler}>
            {loading ? <Loader white inBtn show={true} /> : 'SI'}
          </Button>
          <Button secondary onClick={() => setSubmitModal(false)}>
            NO
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

const CustomFlex = styled(Flex)`
  width: 100%;
  label {
    color: ${({ theme }) => theme.default_color};
    a {
      color: #0000ff;
    }
  }
  /*   label input:checked + .checkmark {
    background-color: ${({ theme }) => theme.default_color};
  } */
  .boxColor {
    background-color: ${({ theme }) => theme.bg_grey_light};
  }
  .boxFlex {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const mapStateToProps = state => {
  return {
    getCheckFormCompiled: state.game.checkFormCompiled,
    userInfo: state.user.user.userinfo,
    game_cms: state.cms.game,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    checkFormCompiled: formId => dispatch(actionCreators.checkFormCompiled(formId)),
    checkFormPrizeAccepted: prize => dispatch(actionCreators.checkFormPrizeAccepted(prize)),
  };
};

// export default Form;
export default connect(mapStateToProps, mapDispatchToProps)(Form);
