import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Table from '../../components/Table/Table';
import { Button, Flex, Input, Loader, Modal, Text } from '../../components/Ui';
import Select from '../../components/Ui/Input/Select/Select';
import customAxiosNm from '../../config/axios-refresh-token';
import { reassociateForm } from '../../formsConfig/formsConfig';
import useForm from '../../hooks/useForm';
function Practices() {
  const [data, setData] = useState([]);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const is_vdr = useSelector(state => state.user.user?.is_vdr);

  const external_id = useSelector(state => state.user.user?.userinfo?.external_id);
  const getPractices = () => {
    const data = {};
    if (is_vdr) {
      data.codice_vdr_aggregato = external_id;
    }
    setError('');

    return customAxiosNm
      .post('/hyundai/getPractices', data)
      .then(res => {
        setData(res.data.practices);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getPractices();
  }, []);
  return <PracticesTable data={data} loading={loading} error={error} getPractices={getPractices} />;
}

export const PracticesTable = ({
  data,
  loading,
  error,
  item,
  score,
  getPractice,
  getPractices,
}) => {
  const [activeRow, setActiveRow] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [itemRreassociate, setItemRreassociate] = useState();
  const [isAssociate, setIsAssociate] = useState(false);
  const [reassociateLoadin, setReassociateLoadin] = useState(false);
  const is_titolare = useSelector(state => state.user.user?.is_titolare);
  const reassociateHandler = async () => {
    setReassociateLoadin(true);
    await customAxiosNm.post('/hyundai/reassociate', {
      codice_vdr_aggregato: itemRreassociate,
      contratto_legacy_id: activeRow?.values?.numero_pratica,
    });

    getPractice ? await getPractice() : await getPractices();
    setActiveRow(undefined);
    setReassociateLoadin(false);
    setOpenConfirmModal(false);
  };

  useEffect(() => {
    setActiveRow(undefined);
  }, [item?.code]);

  const selectRow = row => {
    setActiveRow(row);
    setItemRreassociate(undefined);
    setIsAssociate(false);
  };

  const form = useMemo(() => reassociateForm(activeRow), [activeRow]);
  let datePickerNames = [];
  const sortedObj = { ...form };
  for (var prop in sortedObj) {
    if (sortedObj[prop].elementType === 'datepicker') {
      datePickerNames.push(sortedObj[prop].elementConfig.name);
    }
  }
  const submit = () => {
    console.log(form);
  };
  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    startDate,
    datePickerHandler,
    setFormData,
  } = useForm(submit, sortedObj, datePickerNames, null, null, null, activeRow);
  useEffect(() => {
    setFormData(sortedObj);
  }, [activeRow?.id]);
  // console.log({ formData, sortedObj });
  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }
  let inputs = inputArr.map(inp => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        changedDatePicker={datePickerHandler}
        label={inp.label}
        startdate={startDate}
        isvalid={inp.valid}
        readonly={true}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        radioInputs={inp.inputs}
        {...inp}
      />
    );
  });

  return (
    <Container>
      {loading ? (
        <Loader show={loading} />
      ) : error ? (
        <Text bold align='center'>
          {error}
        </Text>
      ) : (
        <Table
          data={data}
          activeRow={activeRow}
          setActiveRow={selectRow}
          pagination
          initialState={{ pageIndex: 0, pageSize: 10, hiddenColumns: ['dati_riassocia', 'score'] }}
        />
      )}
      {activeRow && (
        <>
          <Text className='mt-20 mb-20' bold>
            Saldo punti utente: {activeRow?.values?.score}
          </Text>
          <div className='form'>
            <div className='form-inputs'>{inputs}</div>
            <div className='form-buttons'>
              {/*   <Button active className='form-btn '>
                AGGIORNA
              </Button> */}
              {activeRow?.values?.dati_riassocia?.length > 0 && !Boolean(is_titolare) && (
                <Button
                  secondary
                  className='form-btn form-btn--reassociate'
                  onClick={() => setIsAssociate(true)}
                >
                  RIASSOCIA
                </Button>
              )}
            </div>
          </div>
          {isAssociate && (
            <>
              <div className='form-reassociate'>
                <Select onChange={e => setItemRreassociate(e.target.value)}>
                  <option value='' disabled selected>
                    Associa a
                  </option>
                  {activeRow?.values?.dati_riassocia?.map(item => (
                    <option value={item.codice_vdr}>{item.associa_a}</option>
                  ))}
                </Select>
                <Button
                  active
                  disabled={!itemRreassociate}
                  onClick={() => setOpenConfirmModal(true)}
                >
                  ESEGUI
                </Button>
              </div>
              {error && (
                <Text bold size={15} color='red'>
                  {error}
                </Text>
              )}
            </>
          )}
        </>
      )}
      <Modal
        show={openConfirmModal}
        close={() => {
          setOpenConfirmModal(false);
        }}
      >
        <Text align={'center'}>Sei sicuro di voler confermare l'operazione?</Text>
        <Flex style={{ marginTop: '20px' }} gap='20px' justify='center'>
          <Button
            active
            loading={reassociateLoadin}
            disabled={reassociateLoadin}
            onClick={() => reassociateHandler()}
          >
            {' '}
            conferma
          </Button>
          <Button secondary onClick={() => setOpenConfirmModal(false)}>
            Annulla
          </Button>
        </Flex>
      </Modal>
    </Container>
  );
};
const Container = styled.div`
  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    & > .form-inputs {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      width: 100%;
      @media (min-width: 821px) {
        width: 65%;
      }
      flex-grow: 1;
      margin-top: 10px;
      justify-content: start;

      & > div {
        margin-top: 10px;
        width: 100%;
        @media (min-width: 821px) {
          width: 32%;
        }
      }
    }
    & > .form-buttons {
      display: flex;
      width: 100%;
      justify-content: start;
      margin-top: 15px;
      @media (min-width: 821px) {
        width: 30%;
      }
      & > button {
        margin-right: 10px;
      }
      .form-btn {
        font-size: 14px;
        height: 40px;

        &--reassociate {
          background-color: #000;
          color: #fff;
          margin-right: 0;
        }
      }
    }
  }
  .form-reassociate {
    margin-top: 40px;
    display: flex;
    gap: 10px;
    justify-content: end;
    align-items: center;
    flex-wrap: wrap;
    @media (min-width: 821px) {
      margin-top: 20px;
      justify-content: start;
    }
    select {
      /* flex-grow: 1; */
      width: 100%;
      margin-bottom: 20px;
      @media (min-width: 821px) {
        width: 46%;
        margin-bottom: 0;
      }
    }
    button {
      font-size: 14px;
      height: 40px;
    }
  }
`;

export default Practices;
