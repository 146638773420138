import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TiPlus, TiMinus } from 'react-icons/ti';
import { Button, Flex, Loader, Text, Wrapper } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
/*import { CgMenuCake } from 'react-icons/cg';*/
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { RiMenuFoldFill } from 'react-icons/ri';
import Input from '../../components/Ui/Input/InputField/InputField';
import { MechanicGoToGame } from '../../components/Games/Mechanics';
import { breakpoints } from '../../theme/_variables';
import customAxiosNm from '../../config/axios-refresh-token';
import Table from '../../components/Table/Table';
import { PracticesTable } from '../Practices/Practices';
import { BiUserCheck, BiUserX } from 'react-icons/bi';
const AccordionContext = React.createContext();

const findItem = (code, name, type, data, section) => {
  for (const item of data) {
    if (item.code === code && item.name === name && item.type === type) {
      return { ...item, section };
    }
    if (item.subitems && item.subitems.length) {
      const foundItem = findItem(code, name, type, item.subitems, section);
      if (foundItem) {
        return { ...foundItem, section };
      }
    }
  }
  return null;
};

function findParent(target, data, parents = []) {
  for (const item of data) {
    const newParents = [...parents, item];
    if (
      item.code?.trim() === target ||
      item.name?.toLowerCase()?.trim().includes(target?.toLowerCase()?.trim())
    ) {
      return newParents;
    }
    if (item.subitems) {
      const foundParent = findParent(target, item.subitems, newParents);
      if (foundParent) {
        return foundParent;
      }
    }
  }
  return null;
}
function mapAndAddProperty(targetCode, data, isOpen) {
  return data.map(item => {
    if (item.code === targetCode) {
      return { ...item, isOpen };
    }

    if (item.subitems) {
      const updatedSubItems = mapAndAddProperty(targetCode, item.subitems, isOpen);
      return { ...item, subitems: updatedSubItems };
    }

    return item;
  });
}
const toggleIsOpen = data => {
  for (const item of data) {
    item.isOpen = false;
    if (item.subitems) {
      toggleIsOpen(item.subitems);
    }
  }
};
function Hierarchy() {
  const { dati_hyundai } = useSelector(state => state?.user?.user || {});
  const [item, setItem] = useState();
  const [practicesList, setPraticesList] = useState();
  const [searchItem, setSearchItem] = useState('');
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(dati_hyundai);
  }, [dati_hyundai]);
  const searchHandler = e => {
    e.preventDefault();
    if (searchItem) {
      toggleIsOpen(data);
      const parentItem = findParent(searchItem, data);
      //const newDAta = data.map(item => {});
      // const mapedData = mapAndAddProperty(
      //   'KD1',
      //   mapAndAddProperty('K14', mapAndAddProperty('1160009', mapAndAddProperty('1002919', data))),
      // );
      let mappedData = data;
      for (let i = 0; i < parentItem?.length; i++) {
        mappedData = mapAndAddProperty(parentItem[i].code, mappedData, true);
      }
      setData([...mappedData]);
    } else {
      setData(dati_hyundai);
    }
  };
  return (
    <AccordionContext.Provider
      value={{
        item,
        setItem,
        data,
        setData,
        practicesList,
        setPraticesList,
      }}
    >
      <div>
        <Search align='end' gap='20px'>
          <Text bold size={30} primary className='label'>
            Gerarchia
          </Text>
          <form onSubmit={searchHandler} className='form'>
            <Input value={searchItem} onChange={e => setSearchItem(e.target.value)} />
            <Button active type='submit'>
              Cerca
            </Button>
          </form>
        </Search>
        <GlobalHeader>
          <Text className='code' bold color='#fff' size={15}>
            CODICE
          </Text>
          <Text className='name' bold color='#fff' size={15}>
            NOME
          </Text>
          <Text className='active' bold color='#fff' size={15}>
            STATO
          </Text>
          <Text className='type' bold color='#fff' size={15}>
            TIPO
          </Text>
        </GlobalHeader>
        {data?.map((item, index) => (
          <AccordionItem key={index} item={item} parentIndex={0} />
        ))}
        <Actions />
      </div>
    </AccordionContext.Provider>
  );
}
const Container = styled.div`
  margin-top: 40px;
  .details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    &-item {
      flex-grow: 1;
      width: 48%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .details-item.locality {
      width: 66%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .points {
    &-item {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ddd;
      padding: 10px 0;
    }
  }
`;

const AccordionHeader = styled.div`
  cursor: ${({ hasSubitems }) => (hasSubitems ? 'pointer' : 'default')};
  padding: 10px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  gap: 5px;

  svg {
    color: ${({ theme }) => theme.primary};
  }

  .details-pratiche {
    font-size: 9px;
    display: flex;
    gap: 10px;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      cursor: pointer;
    }
  }

  .code {
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc(30% - ${({ parentIndex }) => parentIndex * 5}px);
  }

  .name {
    flex-grow: 1;
  }
  .active {
    width: 12%;
    width: calc(12% - ${({ parentIndex }) => parentIndex * 5}px);
  }
  .type {
    width: 25%;
    width: calc(25% + ${({ parentIndex }) => parentIndex * 5}px);
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
  }

  &.open {
    background-color: #e0e0e0;
  }
`;

const Search = styled(Flex)`
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 40px;
  ${respondTo.md`
    justify-content: space-between;
  `}
  .form {
    background-color: #f4f6f9;
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 8px;
    display: flex;
    button {
      border-radius: inherit;
    }
  }
  .form {
    flex-grow: 1;
    width: 100%;
    ${respondTo.md`
    flex-grow: initial;
    width: 35%
  `}
  }
`;
const NestedList = styled.div`
  padding-left: 5px;
  ${respondTo.sm`
    padding-left: 10px;
  `}
`;

const GlobalHeader = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  gap: 5px;
  .code {
    width: 30%;
  }
  .active {
    width: 12%;
  }
  .type {
    width: 25%;
  }
  .name {
    flex-grow: 1;
  }
`;

const AccordionItem = ({ item, parentIndex }) => {
  const [isOpen, setIsOpen] = useState(item.isOpen);
  const {
    item: selectedItem,
    setItem,
    data,
    setData,
    setPraticesList,
  } = useContext(AccordionContext);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setIsOpen(item.isOpen);
  }, [item.isOpen]);
  return (
    <>
      <AccordionHeader
        className={isOpen || item?.code === selectedItem?.code ? 'open' : 'closed'}
        onClick={() => {
          item.subitems && toggleAccordion();
        }}
        parentIndex={parentIndex}
        hasSubitems={item.subitems}
      >
        <span className='code '>
          {item.subitems && <span>{isOpen ? <TiMinus /> : <TiPlus />}</span>} {item.code}
        </span>
        <span className='name'>{item.name}</span>
        <span className='active'>
          {item?.details?.active === 1 ? (
            <BiUserCheck color='green' />
          ) : item?.details?.active === 0 ? (
            <BiUserX color='red' />
          ) : (
            ''
          )}
        </span>
        <div className='type '>
          <span>{item.type}</span>
          {!item.subitems && (
            <div className='details-pratiche'>
              <div
                onClick={() => {
                  window.scrollTo(0, document.body.scrollHeight);
                  setItem(findItem(item.code, item.name, item.type, data, 'pratiche'));
                }}
                style={{
                  color:
                    item?.code === selectedItem?.code &&
                    selectedItem.section === 'pratiche' &&
                    'red',
                }}
              >
                <span>PRATICHE</span>
                <RiMenuFoldFill
                  size={18}
                  color={
                    item?.code === selectedItem?.code &&
                    selectedItem.section === 'pratiche' &&
                    'red'
                  }
                />
              </div>
              <div
                onClick={() => {
                  window.scrollTo(0, document.body.scrollHeight);
                  setItem(findItem(item.code, item.name, item.type, data, 'details'));
                }}
                style={{
                  color:
                    item?.code === selectedItem?.code &&
                    selectedItem.section === 'details' &&
                    'red',
                }}
              >
                <span>DETTAGLIO</span>
                <AiOutlineQuestionCircle
                  size={18}
                  color={
                    item?.code === selectedItem?.code && selectedItem.section === 'details' && 'red'
                  }
                />
              </div>
            </div>
          )}
        </div>
      </AccordionHeader>
      {isOpen && item.subitems && (
        <NestedList>
          {item.subitems.map((item, index) => (
            <AccordionItem key={index} item={item} parentIndex={parentIndex + 1} />
          ))}
        </NestedList>
      )}
    </>
  );
};
const Actions = () => {
  const { item } = useContext(AccordionContext);
  return (
    <Wrapper>
      <Container>
        {item?.section === 'details' && <Details />}
        {item?.section === 'pratiche' && <Pratiche />}
      </Container>
    </Wrapper>
  );
};

const Details = () => {
  const { item } = useContext(AccordionContext);
  return (
    <Wrapper>
      <Text size={20} bold style={{ marginBottom: '10px' }}>
        DETTAGLIO
      </Text>
      <div className='details'>
        <div className='details-item'>
          <Text size={15}>Nominativo</Text>
          <Input value={item?.name} readOnly />
        </div>

        <div className='details-item'>
          <Text size={15}>Azienda</Text>
          <Input value={item?.details.ragione_sociale} readOnly />
        </div>

        <div className='details-item'>
          <Text size={15}>Email</Text>
          <Input value={item?.details.email} readOnly />
        </div>
        <div className='details-item'>
          <Text size={15}>P.iva/C.F</Text>
          <Input value={item?.details.codice_fiscale} readOnly />
        </div>
      </div>
      <Text size={20} bold style={{ marginBottom: '10px', marginTop: '30px' }}>
        PUNTI
      </Text>
      <div className='points'>
        <div className='points-item'>
          <Text size={13}>PUNTI CARICATI</Text>
          <Text size={13}>{item?.points?.punti_caricati}</Text>
        </div>
        <div className='points-item'>
          <Text size={13}>PUNTI SPENDIBILI</Text>
          <Text size={13}>{item?.points?.punti_spendibili}</Text>
        </div>
        <div className='points-item'>
          <Text size={13}>PUNTI NON SPENDIBILI</Text>
          <Text size={13}>{String(item?.points?.punti_nonspendibili)}</Text>
        </div>
        <div className='points-item'>
          <Text size={13}>PUNTI SPESI </Text>
          <Text size={13}>{item?.points?.punti_spesi}</Text>
        </div>
        <div className='points-item'>
          <Text size={13}>PUNTI RIACCREDITATI</Text>
          <Text size={13}>{item?.points?.punti_accreditati}</Text>
        </div>
        <div className='points-item'>
          <Text size={13}>SALDO</Text>
          <Text size={13}>{item?.points?.punti_saldo}</Text>
        </div>
      </div>
      <Text size={20} bold style={{ marginBottom: '10px', marginTop: '30px' }}>
        ORDINI
      </Text>
      <div className='orders'></div>
    </Wrapper>
  );
};
const Pratiche = () => {
  const [activeRow, setActiveRow] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [score, setScore] = useState('');
  const { item, setPraticesList, practicesList, selectedItem } = useContext(AccordionContext);
  const getPractice = () => {
    setLoading(true);
    customAxiosNm
      .post('/hyundai/getPractices', { codice_vdr_aggregato: item.code })
      .then(res => {
        setPraticesList(res.data.practices);
        setScore(res.data.score);
        setLoading(false);
      })
      .catch(err => {
        setError(err.response.data.error);
        setPraticesList([]);
        setLoading(false);
      });
  };
  useEffect(() => {
    setError('');
    getPractice();
  }, [item]);

  return (
    <Wrapper>
      <>
        <Text bold size={30} primary className='label'>
          LISTA {item.name.toUpperCase()}
        </Text>
        <PracticesTable
          data={practicesList}
          loading={loading}
          error={error}
          selectedItem={selectedItem}
          item={item}
          score={score}
          getPractice={getPractice}
        />
      </>
    </Wrapper>
  );
};

export default Hierarchy;
