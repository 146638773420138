import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Col, Flex, Text } from '../../../Ui';
import Ratephoto from '../../Interactions/Ratephoto/Ratephoto';
import { respondTo } from '../../../../theme/mixin';
import { useSelector } from 'react-redux';
import Shares from '../../../Shares';
import ResultGame from '../../Response/ResultGame/ResultGame';
import PreInteraction from '../../PreInteraction/PreInteraction';

const CustomCol = styled(Col)`
  .section-heading > span {
    color: #fff;
  }
  img {
    width: 100%;
    ${respondTo.sm` 
    
   
   `};
  }
`;
const CustomCol1 = styled(Col)`
  .svg {
    width: 100%;
    height: 60px;
    ${respondTo.sm` 
     width:20%;
     height: 95px;
   `};
  }
  .text {
    width: 100%;

    ${respondTo.sm` 
     height:100%;
     width:70%;
 
    display:flex;
flex-direction:column;
justify-content:space-between;
   `};
  }
  a {
    ${respondTo.sm` 
   display: block;
    margin: 20px 0;
   `};
  }
  button {
    margin: 0 auto;
    padding: 0 20px;
    ${respondTo.sm` 
     margin:initial;
     padding:0 50px;
   `};
  }
`;
const SocialShareContainer = styled.div`
  margin-top: 20px;
`;
const UploadReceiptHandler = props => {
  // const {
  //   g: {
  //     id,
  //     ratephoto: { image, uploaded, status },
  //     i18l: { win, loose },
  //   },
  // } = props;

  const {
    g: {
      preinteraction,
      log: { steps },
    },
    played,
  } = props;

  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }

  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction); // true or false in base al fatto che ci sia la prop preinteraction

  let content = null;

  const onShowResult = () => setShowResult(true);

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };

  return (
    <React.Fragment>
      {showResult || played ? (
        <ResultGame {...props} ratephoto />
      ) : !showInteraction && steps.preinteraction.data === null ? (
        <PreInteraction
          preinteraction={preinteraction}
          onShowInteractionHandler={onShowInteractionHandler}
          {...props}
        />
      ) : (
        <Ratephoto typeInteraction='interaction' onShowResult={onShowResult} {...props} />
      )}
    </React.Fragment>
  );
};

export default UploadReceiptHandler;
