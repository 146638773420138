import React, { useEffect } from 'react';

const RedirectView = () => {
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }

  useEffect(() => {
    if (getMobileOperatingSystem() === 'iOS') {
      window.location.replace('https://apps.apple.com/us/app/mrc-kia/id6476549048');
    } else if (getMobileOperatingSystem() === 'Android') {
      window.location.replace('https://play.google.com/store/apps/details?id=it.coromarketing.kia');
    } else {
      window.location.replace('/');
    }
  }, []);

  return <></>;
};

export default RedirectView;
