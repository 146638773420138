import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { respondTo } from '../../../../theme/mixin.js';
import { getSlug } from '../../../../utils/utils';
import Dynamic from '../../Dynamics/Dynamic';
import Fade from '../../../Fade/Fade';
import styled from 'styled-components';
import bggame from '../../../../assets/images/bggame.jpg';
import { Button, Text } from '../../../Ui';
import { LogIn } from 'styled-icons/feather';

const Container = styled.div`
  padding-top: 30px;
  background-image: ${props => `url(${props.bggame})`};
  background-position: center center;
  background-color: ${props => (props.bggamecolor ? props.bggamecolor : '')};
  background-size: cover;
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
  width: 100%;
  min-height: 360px;
  ${respondTo.sm`
 min-height: 500px;
  `}
`;

const TextParent = styled.div`
  text-align: center;
`;
const TextContent = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const TextContainer = styled.div`
  background-color: #fff;
  border-radius: ${({ theme }) => theme.border_radius_generale_grande};
  display: inline-block;
  font-size: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 22px;
  margin-left: 10px;
  margin-right: 10px;

  span {
    font-size: 18px;
  }
  ${respondTo.sm`
	font-size: 18px;
	margin-left: 10px;
  `}
`;
const TextContainerDescription = styled.div`
  background-color: #fff;
  border-radius: ${({ theme }) => theme.border_radius_generale_grande};
  display: inline-block;
  font-size: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 22px;
  margin: 15px auto;
  margin-top: 30px;
  width: 95%;

  height: 300px;
  overflow-y: auto;
  span {
    font-size: 18px;
  }
  ${respondTo.md`
  height: 400px;
   width: 70%;
	font-size: 18px;
	margin-left: 10px;
  `}
`;

const WrapperGame = styled.div`
  max-width: 960px;
  margin: 36px auto;
  max-width: 96%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ${respondTo.med`
		max-width: 90%;
	`}
  ${respondTo.sm`
		max-width: 760px;
	`}
	${respondTo.md`
		max-width: 830px;
	`}
`;

const Click = props => {
  const {
    onShowResult,
    lang,
    typeInteraction,
    g: {
      option,
      played,
      id,
      interaction,
      preinteraction,
      log: { sid },
      i18l: {
        seo: { description },
      },
    },
    action: { data: dataAction, loading },
  } = props;

  const [action, setAction] = useState(false);

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === 'preinteraction') {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  const {
    i18l: {
      it: { textUp, buttonLabel },
    },
  } = rules;

  useEffect(() => {
    if (dataAction.length > 0) {
      setTimeout(() => {
        onShowResult();
      }, 1500);
    }
  }, [dataAction]);

  const clickFinalHandler = index => {
    setAction(true);
  };

  const [widthImg, setWidthImg] = useState(0);
  const [heightImg, setHeightImg] = useState(0);
  //recuperer le height et le width de l image pour le metre dans container

  let imgSize = new Image();
  imgSize.src = require('../../../../assets/images/bggame.jpg');

  imgSize.onload = () => {
    setWidthImg(imgSize.width);
    setHeightImg(imgSize.height);
  };

  return (
    <React.Fragment>
      <Container width={widthImg} height={heightImg}>
        <Fade show={true}>
          {textUp && (
            <TextParent>
              <TextContainer>
                <Text>{textUp}</Text>
              </TextContainer>
            </TextParent>
          )}
          {description && (
            <TextParent>
              <TextContainerDescription>
                <Text>{description}</Text>
              </TextContainerDescription>
            </TextParent>
          )}
          <WrapperGame>
            <Button active type='' onClick={() => clickFinalHandler()}>
              {buttonLabel}
            </Button>
          </WrapperGame>
        </Fade>
      </Container>
      {action ? (
        <Dynamic
          id={id}
          slug={slug}
          section={typeInteraction}
          action={props.action}
          sid={sid}
          {...props}
        />
      ) : null}
    </React.Fragment>
  );
};

Click.propTypes = {};

export default Click;
