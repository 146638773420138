import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Profile from '../Profile';
import Code from '../Code';
import Prize from '../Prize';
import Form from '../../Form';
import ResultDynamic from '../ResultDynamic';
import Fade from '../../../Fade';
import { Button, Section, Typography, Wrapper } from '../../../Ui';
import { getElement } from '../../../../utils/utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ResultContainer = styled.div`
  text-align: center;
  /* flex-grow: 1; */
  background-image: ${props => `url(${props.bgresult})`};
  background-color: ${props => `${props.bgresultcolor}`};
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 60px;
  border-radius: ${({ theme }) => theme.border_radius_generale_grande};
`;

const ContainerProfile = styled.div`
  background-image: ${props => `url(${props.bg})`};
  background-color: ${props => `${props.bgcolor}`};
  width: 100vw;
`;

const ResultGame = props => {
  const {
    g: {
      id,
      interaction: { data: rules },
      log,
      log: { steps, won, code, error, form: formResponse, prize_image, prize_barcode, formend },
      i18l,
      text_color,
      check: { attemptslimit },
      i18l: {
        post_accettazione_premio: { content },
        info: {},
      },
      dynamic: { type },
      form,
    },
    lang,
    played,
    action,
    action: { cleanData },
    memory,
    ratephoto,
  } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      cleanData(id);
    };
  }, []);

  let theData = {};
  let theProfileData = action.data ? getElement(action.data, id) : {};

  if (played) {
    theData = steps.interaction.data;
  } else {
    theData = theProfileData?.data ? theProfileData?.data : null;
  }

  let rulesData = JSON.parse(rules);
  let logData = theData ? JSON.parse(theData) : null;
  let playslog = theProfileData ? theProfileData.playslog : log.playslog;
  let userWon = theProfileData ? theProfileData.won : memory ? formend === false : won;
  //let userWon = theProfileData ? JSON.parse(theProfileData?.data)?.won : won;
  let thePrize = theProfileData ? theProfileData.prize : log.prize;
  let confirm = theProfileData ? theProfileData.confirm : log.confirm;
  let withcode = theProfileData ? theProfileData.withcode : log.withcode;
  let theCodeData = {};

  if (theProfileData) {
    theCodeData = {
      code: theProfileData.code,
      error: theProfileData.error,
      prize_barcode: theProfileData.prize_barcode,
      withcode: theProfileData.withcode,
    };
  } else {
    theCodeData = { code: code, error: error, prize_barcode: null, withcode };
  }

  const {
    settings: { aspect, socialShare, summary, onlywin },

    i18l: looseI18l,
  } = rulesData || { settings: {}, i18l: {} };
  const { bgresult, bgresultcolor } = aspect || {};
  const looseResult = looseI18l?.it?.results?.loose;

  let resultDynamic = null;
  if (attemptslimit && (type === 'noprize' || type === 'preassigned')) {
    resultDynamic = 'info';
  } else if (userWon) {
    resultDynamic = 'win';
  } else {
    resultDynamic = 'loose';
  }

  let dynamicData = i18l[resultDynamic];

  let profileData = logData
    ? {
        socialShare,
        summary,
        profile: logData?.profile,
        attempts: logData?.attempts,
        nAttempts: logData?.nAttempts,
      }
    : looseI18l?.it?.results?.loose
    ? { profile: looseI18l.it.results.loose }
    : {};

  const checkFormCompiled = useSelector(state => state.game.formCompiled);
  const checkFormPrizeAccepted = useSelector(state => state.game.formCompiledPrizeAccepted);
  const [refuseForm, setRefuseForm] = useState(false);

  return (
    <Wrapper>
      <ResultContainer /* bgresultcolor={bgresultcolor}  bgresult={bgresult}*/>
        {!refuseForm && !attemptslimit && !ratephoto && (
          <Fade show={true}>
            {logData !== null || (!userWon && memory && onlywin) ? (
              <Profile
                profileData={profileData}
                bg={bgresult}
                bgcolor={bgresultcolor}
                text_color={text_color}
              />
            ) : null}
          </Fade>
        )}

        {!refuseForm && (
          <Fade show={true}>
            {won !== null || theProfileData?.won !== null ? (
              <ResultDynamic
                dynamicData={dynamicData}
                checkFormCompiled={checkFormCompiled}
                checkFormPrizeAccepted={checkFormPrizeAccepted}
                resultContent={content}
                text_color={text_color}
              />
            ) : null}
          </Fade>
        )}
        {userWon && thePrize ? (
          !refuseForm ? (
            <Prize prize={thePrize} prize_image={prize_image} text_color={text_color} />
          ) : null
        ) : null}
        {/* {(theCodeData.code !== null || theCodeData.error !== null) &&
        withcode &&
        checkFormCompiled &&
        checkFormPrizeAccepted ? (
          theCodeData.prize_barcode || prize_barcode ? (
            <img src={theCodeData.prize_barcode || prize_barcode} className='mt-20 mb-20' />
          ) : (
            <Code theCodeData={theCodeData} text_color={text_color} />
          )
        ) : null} */}
        {userWon && form && form.type === 'post' ? (
          <Form
            form={form}
            formResponse={formResponse}
            lang={lang}
            id={id}
            playslog={playslog}
            played={played}
            bgresultcolor={bgresultcolor}
            setRefuseForm={setRefuseForm}
            text_color={text_color}
          />
        ) : (
          <Link to='/'>
            <Button active upper className='mt-30'>
              Torna alla home
            </Button>
          </Link>
        )}
      </ResultContainer>
    </Wrapper>
  );
};

export default ResultGame;
