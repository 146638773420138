import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardElement from './CardElement';
import { respondTo } from '../../../../theme/mixin.js';
import { getSlug } from '../../../../utils/utils';
import Dynamic from '../../Dynamics/Dynamic';
import Fade from '../../../Fade/Fade';
import styled from 'styled-components';
import bggame from '../../../../assets/images/bggame.jpg';
import { Wrapper } from '../../../Ui';

const Container = styled.div`
  background-image: ${props => (props.bggame ? `url(${props.bggame})` : `url(${bggame})`)};
  background-position: center center;
  background-color: ${props => (props.bggamecolor ? props.bggamecolor : '')};
  background-size: cover;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.border_radius_generale_grande};
  overflow: auto;
  flex: 1;
  width: 100%;
  //height: ${({ height }) => height}px;
  margin: 0 auto;
  padding: 30px 60px;
  min-height: 360px;
  ${respondTo.sm`
 min-height: 500px;
  `}
`;

const TextParent = styled.div`
  text-align: center;
`;
const TextContainer = styled.div`
  background-color: #fff;
  border-radius: 60px;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 22px;
  margin-left: 10px;
  margin-right: 10px;
  ${respondTo.sm`
	font-size: 22px;
	margin-left: 10px;
  `}
`;

const WrapperGame = styled.div`
  max-width: 960px;
  margin: 36px auto;
  display: flex;
  justify-content: center;

  max-width: 96%;
  ${respondTo.med`
		max-width: 90%;
	`}
  ${respondTo.sm`
		max-width: 760px;
	`}
	${respondTo.md`
		max-width: 830px;
	`}
`;

const Card = props => {
  const {
    onShowResult,
    lang,
    typeInteraction,
    g: {
      option,
      played,
      id,
      interaction,
      preinteraction,
      log: { sid },
    },
    action: { data: dataAction, loading },
  } = props;

  const [finalCards, setFinalCards] = useState([]);
  const [action, setAction] = useState(false);

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === 'preinteraction') {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  const {
    settings: {
      numberofcards,
      aspect: { brand, loosecard, wincard, bggame, bggamecolor },
    },
    i18l: {
      it: { textUp },
    },
  } = rules;

  useEffect(() => {
    let data = [];
    for (let i = 0; i < numberofcards; i++) {
      data.push({
        id: i,
        open: false,
        brand,
        loosecard,
        wincard,
      });
    }
    setFinalCards(data);
  }, []);

  useEffect(() => {
    if (dataAction.length > 0) {
      setTimeout(() => {
        onShowResult();
      }, 1500);
    }
  }, [dataAction]);

  const clickCardHandler = index => {
    finalCards[index].open = true;
    setAction(true);
  };
  const [widthImg, setWidthImg] = useState(0);
  const [heightImg, setHeightImg] = useState(0);
  //recuperer le height et le width de l image pour le metre dans container

  let imgSize = new Image();
  imgSize.src = require('../../../../assets/images/bggame.jpg');

  imgSize.onload = () => {
    setWidthImg(imgSize.width);
    setHeightImg(imgSize.height);
  };

  return (
    <Wrapper>
      <Container bggame={bggame} bggamecolor={bggamecolor} width={widthImg} height={heightImg}>
        <Fade show={true}>
          {textUp && (
            <TextParent>
              <TextContainer>{textUp}</TextContainer>
            </TextParent>
          )}
          <WrapperGame>
            {finalCards.map((card, index) => {
              return (
                <CardElement
                  key={card.id}
                  index={index}
                  brand={card.brand}
                  loosecard={card.loosecard}
                  wincard={card.wincard}
                  isOpen={card.open}
                  result={dataAction}
                  idGame={id}
                  loading={loading}
                  clicked={() => clickCardHandler(index)}
                />
              );
            })}
          </WrapperGame>
        </Fade>
      </Container>
      {action ? (
        <Dynamic
          id={id}
          slug={slug}
          section={typeInteraction}
          action={props.action}
          sid={sid}
          {...props}
        />
      ) : null}
    </Wrapper>
  );
};

Card.propTypes = {};

export default Card;
