import React from 'react';
import { UploadReceipt, Memory, UploadVideo } from '../Interactions';

const PreInteraction = props => {
  const { onShowInteractionHandler, preinteraction } = props;

  let content = null;

  switch (preinteraction.type) {
    case 'upload-receipt':
      content = (
        <UploadReceipt
          typeInteraction='preinteraction'
          onShowInteractionHandler={onShowInteractionHandler}
          {...props}
        />
      );
      break;
    case 'upload-video':
      content = (
        <UploadVideo
          typeInteraction='preinteraction'
          onShowInteractionHandler={onShowInteractionHandler}
          {...props}
        />
      );
      break;
    case 'memory':
      content = (
        <Memory
          typeInteraction='preinteraction'
          onShowInteractionHandler={onShowInteractionHandler}
          {...props}
        />
      );
      break;
    default:
      return content;
  }

  return content;
};

export default PreInteraction;
