import React from "react";
import Fb from "./Fb";
import Twitter from "./Twitter";
import styled from "styled-components";

const SharesContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const Shares = (props) => {
	const { socialShare, metatags } = props;

	let shares = null;

	if (socialShare.length > 0) {
		shares = socialShare.map((s) => {
			switch (s) {
				case "facebook":
					return <Fb key={s} metatags={metatags} />;
				case "twitter":
					return <Twitter key={s} />;
			}
		});
	}

	return <SharesContainer>{shares}</SharesContainer>;
};

export default Shares;
