import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { respondTo } from '../../../../../theme/mixin';
import { shuffleArray } from '../../../../../utils/utils';

import Answer from './Answer';

const AnswerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const Answers = props => {
  const {
    options,
    id,
    clicked,
    instantcheck,
    selectedcheck,
    showtrueanswer,
    colorLoose,
    colorWin,
    colorAnswers,
    completed,
  } = props;

  const [isValid, setIsValid] = useState(false);
  const [showTrueAnswer, setShowTrueAnswer] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [tcomplete, setTcomplete] = useState(false);
  const [click, setClick] = useState(false);

  useEffect(
    e => {
      if (completed) {
        setDisabled(true);
        setTcomplete(true);
      } else {
        setDisabled(false);
        setTcomplete(false);
      }
    },
    [completed],
  );

  const onChangeHandler = e => {
    setDisabled(true);
    setTimeout(() => {
      if (instantcheck) {
        if (!selectedcheck && !showtrueanswer) {
          setIsValid(true);
        }
        if (showtrueanswer) {
          setShowTrueAnswer(true);
        }
        //clicked(e, answ);
      } else {
        //clicked(e, answ);
      }
    }, 600);
  };

  let answer = null;
  answer = options.map(opt => {
    return (
      <Answer
        instantcheck={instantcheck}
        selectedcheck={selectedcheck}
        colorLoose={colorLoose}
        colorWin={colorWin}
        colorAnswers={colorAnswers}
        key={opt.id}
        idQuestion={id}
        id={opt.id}
        isvalid={isValid}
        showtrue={showTrueAnswer}
        value={opt.value}
        points={opt.points}
        change={onChangeHandler}
        disabled={disabled}
        tcomplete={tcomplete}
        click={click}
        clicked={clicked}
      >
        {opt.text}
      </Answer>
    );
  });

  return <AnswerContainer>{answer}</AnswerContainer>;
};

Answers.propTypes = {
  options: PropTypes.array,
  id: PropTypes.number,
  clicked: PropTypes.func,
  instantcheck: PropTypes.number,
};

export default Answers;
