import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input, Loader, Flex, Col, Text, Box, Modal, Wrapper } from '../../../Ui';
import ErrorInModal from '../../../ErrorInModal';
import { uploadReceiptForm, uploadVideoForm } from '../../../../formsConfig/formsConfig';
import { useTranslation } from 'react-i18next';
import useForm from '../../../../hooks/useForm';
import Fade from '../../../Fade/Fade';
import customaxios from '../../../../config/axios-refresh-token';
import { getSlug, getElement } from '../../../../utils/utils';
import Dynamic from '../../Dynamics/Dynamic';
import { BsDashCircleDotted } from 'react-icons/bs';
import { respondTo } from '../../../../theme/mixin';
import styled from 'styled-components';
import { BsInfoCircle } from 'react-icons/bs';
import moment from 'moment';
const InputCode = styled.div`
  width: 100%;
  margin: 0 auto;
  ${respondTo.sm`
    width:30%;
`}
`;

const UploadVideo = props => {
  const {
    onShowInteractionHandler,
    typeInteraction,
    g: {
      id,
      interaction,
      preinteraction,
      video_size,
      video_length,
      video_extension,
      text_color,
      preinteraction_image,
      log: {
        sid,
        sidcheck: { collection },
      },
      check_receipt_codes,
      preinteraction_fields,
      i18l: {
        form_preinteraction: { title, content },
      },
    },
    lang,
    action: { data: dataAction, loading },
    onShowResult,
  } = props;
  const [blockFields, setBlockFields] = useState(true);
  const [show, setShow] = useState(false);
  const [loadingUploadForm, setLoadingUploadForm] = useState(false);
  const [action, setAction] = useState(false);
  const [collectDue, setCollectDue] = useState(collection.collectiondue);
  const [collected, setCollected] = useState(collection.collected);

  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetVideo, setResetVideo] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [textModal, setTextModal] = useState('');

  const [showImage, setShowImage] = useState(false);
  const [validationVideo, setValidationVideo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const game_cms = useSelector(state => state.cms.game);
  const pdv = useSelector(state => state.app.config.stores);

  const game_image = game_cms[0]?.contents?.find(e => e.name === 'game_preinteraction_image');
  const game_text = game_cms[0]?.contents?.find(e => e.name === 'game_preinteraction_text');

  const [t] = useTranslation();

  let slug = getSlug();

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === 'preinteraction') {
    rules = JSON.parse(preinteraction.data);
  }

  const setVideo = value => {
    setImmagine(value);
    if (!value) {
      setResetVideo(true);
      setFormIsValid(false);
    }
  };
  const formSetSelectedFiles = file => {
    setSelectedFiles(prevState => {
      return [...prevState, file];
    });
  };

  const uploadForm = uploadVideoForm(
    t('games.date'),
    t('games.number'),
    t('games.amount'),
    t('games.time'),
  );
  let newForm = {};
  if (preinteraction_fields === 'code') {
    newForm['code'] = uploadForm.code;
  } else if (preinteraction_fields === 'code_receipt') {
    newForm = { ...uploadForm };
  } else {
    delete uploadForm.code;
    newForm = { ...uploadForm };
  }
  let fForm = {};
  fForm = { ...newForm, ...rules };

  const submitHandler = () => {
    if (validationVideo) return;
    setIsLoading(true);
    const dataToSend = {};
    for (const property in formData) {
      dataToSend[property] =
        property !== 'date'
          ? property === 'time'
            ? formData[property].valuetosend
            : formData[property].value
          : formData[property].valuetosend;
    }
    var data = new FormData();

    let fileArray = [];
    let fileExtraArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].name === 'file') {
        fileArray.push(selectedFiles[i]);
      } else if (selectedFiles[i].name === 'fileextra') {
        fileExtraArray.push(selectedFiles[i]);
      }
    }

    fileArray.forEach(function (element) {
      if (fileArray[fileArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });
    fileExtraArray.forEach(function (element) {
      if (fileExtraArray[fileExtraArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });

    localStorage.setItem('ratePhotoData', JSON.stringify(dataToSend));
    dataToSend.type = 'receipt';
    data.append('data', JSON.stringify(dataToSend));
    data.append('sid', sid);
    data.append('section', typeInteraction === 'preinteraction' ? 'preinteraction' : 'interaction');
    setLoadingUploadForm(true);

    customaxios
      .post(`game/${slug}/${id}/data`, data)
      .then(response => {
        setIsLoading(false);
        const { data } = response;
        setLoadingUploadForm(false);
        if (response.status === 200) {
          if (typeInteraction === 'preinteraction') {
            if (sid) {
              if (data.log.sidcheck.collection.status) {
                onShowInteractionHandler();
              } else {
                const {
                  log: {
                    sidcheck: { collection },
                  },
                } = data;
                setCollectDue(collection.collectiondue);
                setCollected(collection.collected);
                deleteFileHandler();
                setResetVideo(true);
              }
            } else {
              onShowInteractionHandler();
            }
          } else {
            setAction(true);
          }
        }
      })
      .catch(error => {
        setIsLoading(false);
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400) {
          setTextModal(message);
          setShowModal(true);
          setLoadingUploadForm(false);
        }
      });
  };

  let datePickerNames = [];

  for (var prop in fForm) {
    if (fForm[prop].elementType === 'datepicker') {
      datePickerNames.push(fForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    imgPreviewUrl,
    startDate,
    setFormValid,
    resetFormHandler,
    setFormIsValid,
    formIsValid,
    handleKeyDown,
  } = useForm(submitHandler, fForm, datePickerNames, true);

  // useEffect(() => {
  //   const theRestIsValid = Object.keys(formData).every(key => formData[key].valid);
  //   if (!immagine) {
  //     setFormIsValid(false);
  //   } else if (theRestIsValid && immagine) {
  //     setFormIsValid(true);
  //   }
  // }, [formIsValid, immagine]);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }
  let inputs = inputArr.map(inp => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.elementType === 'inputfile' ? immagine : inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        startdate={inp.value}
        changedDatePicker={date => datePickerHandler(date, inp.name)}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={() => {
          deleteFileHandler();
          formData.file.valid = false;
        }}
        imagePreviewUrl={imgPreviewUrl}
        lang={lang}
        title={inp.title}
        radioInputs={inp.inputs}
        setVideo={setVideo}
        setFormValid={setFormValid}
        formSetSelectedFiles={formSetSelectedFiles}
        resetFormHandler={resetFormHandler}
        resetVideo={resetVideo}
        pdv={pdv}
        onKeyDown={handleKeyDown}
        isToday={formData['date']?.valuetosend === moment(new Date()).format('DD/MM/YYYY')}
        video_size={video_size}
        video_length={video_length}
        video_extension={video_extension}
        setValidationVideo={setValidationVideo}
        {...inp}
      />
    );
  });

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (imgPreviewUrl) {
      setBlockFields(false);
    } else {
      setBlockFields(true);
    }
  }, [imgPreviewUrl]);

  useEffect(() => {
    if (immagine) {
      setBlockFields(false);
    } else {
      setBlockFields(true);
      setFormIsValid(false);
    }
  }, [immagine]);

  useEffect(() => {
    if (dataAction?.length > 0) {
      let isData = getElement(dataAction, id);

      if (isData) {
        if (typeInteraction !== 'preinteraction') {
          onShowResult();
        }
      }
    }
  }, [dataAction]);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Wrapper>
      <Fade>
        {Boolean(
          preinteraction_fields === 'receipt' || preinteraction_fields === 'code_receipt',
        ) && (
          <Box fullheight className='mb-20 box' style={{ padding: '30px 15px' }}>
            {title && (
              <Text style={{ textAlign: 'center' }} color={text_color} className='mb-20'>
                {title}
              </Text>
            )}

            <div style={{ width: 'fit-content', margin: '0 auto' }}>{inputs[0]}</div>
          </Box>
        )}

        <Button
          style={{ margin: '0 auto' }}
          loading={loadingUploadForm}
          active
          onClick={e => formSubmitHandler(e)}
          disabled={isLoading}
        >
          {t('app.next')}
        </Button>

        {action ? <Dynamic id={id} slug={slug} section={typeInteraction} {...props} /> : null}
      </Fade>
      <Modal alert show={showModal} close={closeModal}>
        <ErrorInModal error={textModal} />
      </Modal>
      <Modal alert show={showImage} close={() => setShowImage(false)}>
        <img src={preinteraction_image} alt='' />
      </Modal>
    </Wrapper>
  );
};

export default UploadVideo;
