import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-awesome-styled-grid';
import { Typography, SummaryGame, Video, Image, Wrapper, Text } from '../../../Ui';
import Shares from '../../../Shares';
import { respondTo } from '../../../../theme/mixin';

const Container = styled.div`
  // background-image: ${props => `url(${props.bg})`};
  //background-color: ${props => `${props.bgcolor}`};
  width: 100vw;
`;

const CustomRow = styled(Row)`
  padding: 16px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  ${respondTo.sm`
        padding: 32px 0;
    `}
`;

const ResultMedia = styled.div`
  margin-bottom: 20px;
  ${respondTo.sm`
        margin-bottom: 0;
    `}
`;
const ResultText = styled.div``;
const ResultTitle = styled.div`
  margin-bottom: 12px;
`;
const SocialShareContainer = styled.div`
  margin-top: 20px;
`;

const Profile = props => {
  const { profileData, bg, bgcolor, text_color } = props;

  //const { profile, attempts, nAttempts, socialShare, summary } = profileData || {};

  const { title, content, media } = profileData?.profile || {};

  const { type, src } = profileData?.profile?.media || {};

  let resultMedia = null;

  switch (profileData?.profile?.type) {
    case 'video':
      resultMedia = <Video src={src} />;
      break;
    case 'image':
      resultMedia = <Image src={src} />;
      break;
    default:
      return null;
  }

  let metatags = {
    title: title,
    description: content,
    url: window.location.href,
    image: src,
    type: 'website',
  };

  return (
    <Container /* bg={bg}  bgcolor={bgcolor}*/>
      <Wrapper>
        <CustomRow>
          <Col xs={4} sm={resultMedia ? 6 : 12} md={resultMedia ? 6 : 12} lg={resultMedia ? 6 : 12}>
            {/*  {summary ? <SummaryGame right={attempts} total={nAttempts} /> : null} */}
            <ResultTitle>
              <Text color={text_color} align='center' className='mb-20 title'>
                {profileData?.profile?.title}
              </Text>
            </ResultTitle>
            <Text align='center' color={text_color}>
              {profileData?.profile?.content}
            </Text>
            {/*   {socialShare.length > 0 ? (
              <SocialShareContainer>
                <Shares socialShare={socialShare} metatags={metatags} />
              </SocialShareContainer>
            ) : null} */}
          </Col>
          {/*   {resultMedia ? (
            <Col xs={4} sm={6} md={6} lg={6}>
              <ResultMedia>{resultMedia}</ResultMedia>
            </Col>
          ) : null} */}
        </CustomRow>
      </Wrapper>
    </Container>
  );
};

export default Profile;
