import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '../../../../../Ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
const CustomButton = styled(Button)`
  background-color: #fff !important;
  color: ${({ theme }) => theme.default_text_color} !important;
`;

const CopyButton = props => {
  const [copied, setCopied] = useState(false);
  const { data } = props;
  const [t] = useTranslation();

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  return (
    <CopyToClipboard text={data} onCopy={() => setCopied(true)}>
      <CustomButton upper active>
        {copied ? t('games.copied') : t('games.copycode')}
      </CustomButton>
    </CopyToClipboard>
  );
};

CopyButton.propTypes = {
  data: PropTypes.string,
};

export default CopyButton;
