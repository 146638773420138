import React from 'react';
import PropTypes from 'prop-types';
import CopyButton from './CopyButton';
import styled from 'styled-components';
import { respondTo } from '../../../../../theme/mixin';

const DisplayCodeInput = styled.div`
  background-color: ${props => props.theme.bgcode};
  padding: 12px;
  text-align: center;
  margin: 0 auto 30px;
  margin-right: ${props => (props.small ? '0px' : '0')};
  font-size: ${props => (props.small ? '18px' : '28px')};
  display: ${props => (props.small ? 'inline-block' : 'block')};
  border: 1px solid ${props => props.theme.borderCode};
  ${respondTo.sm`
        font-size: ${props => (props.small ? '25px' : '40px')};
    `}
`;

const DisplayCode = props => {
  const { code, text_color } = props;

  return (
    <React.Fragment>
      <DisplayCodeInput {...props}>{code}</DisplayCodeInput>
      {/* <div>
        <CopyButton data={code} />
      </div> */}
    </React.Fragment>
  );
};

DisplayCode.propTypes = {
  code: PropTypes.string,
};

export default DisplayCode;
