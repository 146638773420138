import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../Ui';
import styled from 'styled-components';
import { getProtocol } from '../../../utils/utils';
import { Facebook } from 'styled-icons/boxicons-logos';

const FbButton = styled(Button)`
  font-size: 16px;
  min-width: 8.625rem;
`;

const Fb = props => {
  const [t] = useTranslation();
  const {
    metatags: { title, description, url, image }
  } = props;

  let protocol = getProtocol();

  const handlShare = () => {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100`;
    let shareparams = `r=${title}QMIFB${description}QMIFB${url}QMIFB${image}`;
    let u = `${protocol}/fb-share.php?${shareparams}`;
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${u}`,
      'sharer',
      params
    );
  };

  return (
    <FbButton onClick={handlShare} bgcolor="#3b5998">
      <Facebook size={30} />
      {t('app.share')}
    </FbButton>
  );
};

export default Fb;
