import React, { useState, useMemo, useEffect } from 'react';
import { Button, Input, Text, Wrapper } from '../../components/Ui';
import { useTable, useFilters, usePagination } from 'react-table';
import styled from 'styled-components';
import useForm from '../../hooks/useForm';
import { reassociateForm } from '../../formsConfig/formsConfig';
import moment from 'moment';
import { BiUserCheck, BiUserX } from 'react-icons/bi';
import ReactExport from 'react-export-excel';
import { IoMdDownload } from 'react-icons/io';

function Table({ data, activeRow, setActiveRow, pagination, initialState }) {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const columnsFile = [
    <ExcelColumn value='codice_dealer' label='COD. DEALER' />,
    <ExcelColumn value='vdr' label='VDR' />,
    <ExcelColumn value='codice_vdr' label='COD. VDR' />,
    <ExcelColumn value='numero_pratica' label='N.PRATICA' />,
    <ExcelColumn value='punti' label='PUNTI' />,
    <ExcelColumn value='disponibile' label='DISPONIBILE' />,
    <ExcelColumn value='active' label='ATTIVO' />,
    <ExcelColumn value='data_caricamenti' label='DATA CARICAMENTO' />,
    <ExcelColumn value='data_autorizzazione' label='DATA AUTORIZZAZIONE' />,
  ];
  const columns = useMemo(
    () => [
      {
        Header: 'COD. DEALER',
        accessor: 'codice_dealer',
        width: 100,
      },
      {
        Header: 'VDR',
        accessor: 'vdr',
        width: 200,
      },
      {
        Header: 'COD. VDR',
        accessor: 'codice_vdr',
        width: 120,
      },
      {
        Header: 'N.PRATICA',
        accessor: 'numero_pratica',
        width: 120,
      },
      {
        Header: 'PUNTI',
        accessor: 'punti',
        width: 60,
      },
      {
        Header: 'DISPONIBILE',
        accessor: 'disponibile',
        width: 95,
      },
      {
        Header: 'ATTIVO',
        accessor: 'active',
        Cell: ({ row }) => {
          return row.values.active ? <BiUserCheck color='green' /> : <BiUserX color='red' />;
        },
        width: 60,
      },
      {
        Header: 'DATA CARICAMENTO',
        accessor: 'data_caricamenti',
        Cell: ({ row }) => {
          return moment(row.values.data_caricamenti).format('DD/MM/YYYY');
        },
        width: 120,
      },
      {
        Header: 'DATA AUTORIZZAZIONE',
        accessor: 'data_autorizzazione',
        width: 135,
      },

      {
        Header: 'dati_riassocia',
        accessor: 'dati_riassocia',
      },
      {
        Header: 'score',
        accessor: 'score',
      },
    ],
    [],
  );
  const [activeColumn, setActiveColumn] = useState();
  const [filterRow, setFilterRow] = useState('');
  const DefaultColumnFilter = ({ column: { filterValue, setFilter, id } }) => {
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilterRow(e.target.value);
          setFilter(e.target.value || undefined);
        }}
        placeholder='Filter...'
        style={{ padding: '5px', width: '100%' }}
        onFocus={() => setActiveColumn(id)}
        onBlur={() => setActiveColumn(undefined)}
      />
    );
  };
  console.log({ filterRow });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    setPageSize,
    pageCount,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetHiddenColumns: false,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState, // Set initial page index and size
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    usePagination,
  );

  const ClickHandler = row => {
    setActiveRow(row);
  };

  return (
    <>
      <Container>
        <table
          {...getTableProps()}
          style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}
        >
          <thead className='head'>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} style={{ minWidth: column.width + 'px' }}>
                    <div className='head-top'>{column.render('Header')}</div>
                    <div style={{ padding: '5px', backgroundColor: '#f2f2f2' }}>
                      {column.render('Filter')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    borderBottom: '1px solid #ddd',
                    backgroundColor: activeRow?.id === row.id ? '#3a6077' : '',
                    color: activeRow?.id === row.id ? '#cccccc' : '',
                  }}
                  className='row'
                  onClick={() => ClickHandler(row)}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '5px',
                          borderBottom: '1px solid #ddd',
                          backgroundColor: activeColumn === cell.column.id ? '#f1f1f1' : '',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
      {pagination && (
        <Pagination>
          <div>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>
            <Text size={14} bold>
              Page {pageIndex + 1} of {pageOptions?.length}
            </Text>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </button>
          </div>
          <div>
            <Text size={14} bold>
              | Go to page:{' '}
              <input
                type='number'
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '50px' }}
              />
            </Text>
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Pagination>
      )}
      <ExcelFile
        element={
          <Button active style={{ marginTop: '100px' }}>
            <span>Export</span> <IoMdDownload color='#fff' />
          </Button>
        }
        filename='Practices'
      >
        <ExcelSheet data={rows.map(val => val.values)} name='Actions'>
          {columnsFile}
        </ExcelSheet>
      </ExcelFile>
    </>
  );
}
const Container = styled.div`
  overflow: auto;
  /* height: 100vh; */
  padding: 5px;
  table tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  .head {
    background-color: #fff;
    th {
      border: 1px solid #fff;
    }
  }
  .head-top {
    background-color: ${({ theme }) => theme.primary};
    color: #fff;
    font-size: 14px;
    text-wrap: nowrap;
    padding: 10px;
  }
  .row:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
  td {
    font-size: 14px;
  }
  &::-webkit-scrollbar {
    width: 7px; /* You can adjust the width of the scrollbar */
  }

  /* Style the scrollbar thumb (the draggable part) */
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.primary}; /* Change the color to your desired color */
    border-radius: 4px; /* You can adjust the border-radius for rounded corners */
  }

  /* Style the scrollbar track when the thumb is being hovered */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #000; /* Change the color to your desired hover color */
  }
  &::-webkit-scrollbar:horizontal {
    height: 7px; /* You can adjust the height of the horizontal scrollbar */
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 15px;
  button {
    background-color: ${({ theme }) => theme.bg_bottoni};
    color: ${({ theme }) => theme.colore_testo_bottoni};
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    margin: 0 10px;
  }
`;

export default Table;
