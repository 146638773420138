import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Text } from '../../../Ui';
import ErrorInModal from '../../../ErrorInModal';
import Board from './Board';
import DisplayCode from './DisplayCode';
import Fade from '../../../Fade';

const CustomBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: ${({ theme }) => theme.bg_grey_light};
  border: none;
  padding: 20px;
`;
const DisplayCodeContainer = styled.div`
  margin-top: 24px;
`;

const DefaultCodeContainer = styled.div`
  margin-top: 0px;
`;

const Code = props => {
  const {
    theCodeData: { error, code, withcode },
    text_color,
  } = props;

  const [t] = useTranslation();

  return (
    <CustomBox>
      <Fade show={true}>
        <DefaultCodeContainer>
          <Typography as='p' type='text' align='center'>
            <Text color={text_color}>{t('games.getcoderesponse')}</Text>
          </Typography>
          <DisplayCodeContainer>
            {code && withcode ? <DisplayCode small code={code} text_color={text_color} /> : null}
            {error ? <ErrorInModal error={error} /> : null}
          </DisplayCodeContainer>
        </DefaultCodeContainer>
      </Fade>
    </CustomBox>
  );
};

export default Code;
