import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Typography } from '../../../../../Ui';
import { darken, lighten } from 'polished';
import { respondTo } from '../../../../../../theme/mixin';

const SingleAnswer = styled.div`
  margin-bottom: 8px;
  width: 100%;
  ${respondTo.sm`
        width:48%;
        margin-bottom: 8px;
    `}
`;

const AnswerButton = styled(Button)`
  width: 100%;
  display: block;
  font-size: 18px;
  height: auto;
  padding: 12px;
  // opacity: ${props => (props.disabled ? (props.tcomplete ? '.5' : '1') : '1')};
  margin: auto;
  //box-shadow: 2px 2px 6px 0px rgba(201, 201, 201, 1);
  // text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  //border-radius: 60px;
  // background: ${props => (props.isSelected ? darken(0.1, props.colorAnswers) : '')};
  color: ${props =>
    props.isvalid
      ? props.value === 1
        ? props.theme.colore_testo_bottoni || props.theme.default_text_color
        : props.theme.colore_testo_bottoni || props.theme.default_text_color
      : props.tcomplete
      ? lighten(0.1, props.theme.colore_testo_bottoni || props.theme.default_text_color)
      : props.theme.default_color};
  border: ${({ theme }) => '1px solid ' + theme.colore_bordo_box};
  background: ${props =>
    props.isSelected ? props.theme.bg_bottoni || props.theme.primary : '#fff'};
  background: ${props =>
    props.isvalid
      ? props.value === 1
        ? props.theme.bg_bottoni || props.theme.primary
        : props.theme.bg_bottoni || props.theme.primary
      : props.tcomplete
      ? lighten(0.1, props.theme.bg_bottoni || props.theme.primary)
      : '#fff'};
  &:hover {
    background: ${props =>
      props.isvalid
        ? props.value === 1
          ? props.theme.bg_bottoni || props.theme.primary
          : props.theme.bg_bottoni || props.theme.primary
        : props.tcomplete
        ? lighten(0.1, props.theme.bg_bottoni || props.theme.primary)
        : darken(0.1, props.theme.bg_bottoni || props.theme.primary)};
    color: ${props =>
      props.isvalid
        ? props.value === 1
          ? props.theme.colore_testo_bottoni || props.theme.default_text_color
          : props.theme.colore_testo_bottoni || props.theme.default_text_color
        : props.tcomplete
        ? lighten(0.1, props.theme.colore_testo_bottoni || props.theme.default_text_color)
        : darken(0.1, props.theme.colore_testo_bottoni || props.theme.default_text_color)};
  }

  /*   background: ${props =>
    props.showtrue
      ? props.value === 1
        ? props.colorWin
        : props.colorAnswers
      : props.tcomplete
      ? lighten(0.1, props.colorAnswers)
      : props.colorAnswers}; */
  /*   &:hover {
    background: ${props =>
    props.showtrue
      ? props.value === 1
        ? props.colorWin
        : props.colorAnswers
      : props.tcomplete
      ? lighten(0.1, props.colorAnswers)
      : darken(0.1, props.colorAnswers)};
  } */

  ${respondTo.sm`
        font-size: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
    `}
`;

const Answer = props => {
  const [dis, setDis] = useState(false);
  //const [isValid, setIsValid] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const answerRef = useRef(null);

  const {
    value,
    id,
    idQuestion,
    change,
    disabled,
    points,
    children,
    clicked,
    instantcheck,
    selectedcheck,
  } = props;

  const handleClick = e => {
    setDis(true);
    //clicked();
    change();

    setIsSelected(true);

    let answ = {};
    answ.result = value.toString();
    answ.questionid = idQuestion.toString();
    answ.answerid = id.toString();
    answ.points = points.toString();
    // answ.result = e.currentTarget.getAttribute('value');
    // answ.questionid = e.currentTarget.getAttribute('idquestion');
    // answ.answerid = e.currentTarget.getAttribute('id');
    // answ.points = e.currentTarget.getAttribute('points');

    setTimeout(() => {
      if (instantcheck) {
        if (selectedcheck) {
          answerRef.current &&
            answerRef.current.setAttribute(
              'style',
              `background-color: ${value === 1 ? props.colorWin : props.colorLoose};`,
            );
        }
        clicked(e, answ);
      } else {
        clicked(e, answ);
      }
    }, 600);

    answerRef.current &&
      answerRef.current.setAttribute(
        'style',
        `background-color: ${darken(0.1, props.colorAnswers)};`,
      );
  };

  // LIFT UP STATE ?? DISABLE AND ISVALID

  return (
    <SingleAnswer {...props}>
      <AnswerButton
        ref={answerRef}
        type='answer'
        name={`radio${idQuestion}`}
        id={id}
        idquestion={idQuestion}
        points={points}
        value={value}
        //onClick={change}
        onClick={handleClick}
        disabled={disabled}
        //disabled={true}
        //disabled="disabled"
        isSelected={isSelected}
        {...props}
      >
        <Typography as='p' type='answer' align='center'>
          {children}
        </Typography>
      </AnswerButton>
    </SingleAnswer>
  );
};

Answer.propTypes = {
  change: PropTypes.func,
  value: PropTypes.number,
  id: PropTypes.number,
  idQuestion: PropTypes.number,
  points: PropTypes.number,
  isvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default Answer;
