import React, { useState } from "react";
import PropTypes from "prop-types";
import { SurveyQuiz } from "../../Interactions";
import { Loader } from "../../../Ui";
import PreInteraction from "../../PreInteraction";
import ResultGame from "../../Response/ResultGame";

const SurveyQuizHandler = (props) => {
	const {
		g: {
			preinteraction,
			log: { steps },
		},
		played,
		action: { loading },
	} = props;

	let showPreInteraction = false;

	if (preinteraction !== null) {
		showPreInteraction = true;
	}

	const [showResult, setShowResult] = useState(false);
	const [showInteraction, setShowInteraction] = useState(!showPreInteraction);

	let content = null;

	const onShowResult = () => setShowResult(true);

	const onShowInteractionHandler = () => {
		setShowInteraction(true);
	};

	content = (
		<React.Fragment>
			{loading ? (
				<Loader show={loading} full />
			) : showResult || played !== null ? (
				<ResultGame {...props} />
			) : !showInteraction && steps.preinteraction.data === null ? (
				<PreInteraction
					preinteraction={preinteraction}
					onShowInteractionHandler={onShowInteractionHandler}
					{...props}
				/>
			) : (
				<SurveyQuiz
					typeInteraction="interaction"
					onShowResult={onShowResult}
					{...props}
				/>
			)}
		</React.Fragment>
	);

	return content;
};

SurveyQuizHandler.propTypes = {
	action: PropTypes.object,
};

export default SurveyQuizHandler;
