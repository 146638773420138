import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';
import { Text } from '../../../Ui';

const ResultDynamicWrapper = styled.div`
  position: relative;
  text-align: center;
  margin-top: 15px;
  //border: 1px solid ${({ theme }) => theme.borderCode};
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  border: 10px;
`;

const ResultDynamic = props => {
  const {
    dynamicData: { title, content },
    checkFormCompiled,
    checkFormPrizeAccepted,
    resultContent,
    text_color,
  } = props;

  return (
    <ResultDynamicWrapper>
      <Text color={text_color} align='center' className='mb-20 title'>
        {title}
      </Text>
      <Text align='center' color={text_color}>
        {checkFormCompiled && checkFormPrizeAccepted ? resultContent : content}
      </Text>
    </ResultDynamicWrapper>
  );
};

export default ResultDynamic;
