import React from 'react';
import styled from 'styled-components';
import { Flex, Col, Text, Button, Box, Input, Wrapper } from '../../../Ui';

import { useDispatch } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';

import { respondTo } from '../../../../theme/mixin';
import { concorsoForm } from '../../../../formsConfig/formsConfig';
import useForm from '../../../../hooks/useForm';
import Fade from '../../../Fade';
import customAxios from '../../../../config/axios-refresh-token';
import { getSlug } from '../../../../utils/utils';
import moment from 'moment';
import { getGame } from '../../../../store/actions';
import Dynamic from '../../Dynamics/Dynamic';
import { useEffect } from 'react';

const Ratephoto = props => {
  const {
    typeInteraction,
    onShowResult,
    g: {
      id,
      i18l,
      log: { sid },
    },
    pdf,
    text_color,
    action: { data: dataAction, getDynamic, loading },
  } = props;
  const slug = getSlug();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetImage, setResetImage] = useState(false);
  const [info, setInfo] = useState('');
  const [action, setAction] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const dataForm = concorsoForm({ maxDate: new Date('07-30-2023'), pdf });

  const setImage = value => {
    setImmagine(value);
    if (!value) {
      setResetImage(true);
    }
  };
  const formSetSelectedFiles = file => {
    setSelectedFiles(prevState => {
      return [...prevState, file];
    });
  };

  const closeModal = () => {
    setInfo('');
    setShowModal(false);
  };

  let datePickerNames = [];

  for (var prop in dataForm) {
    if (dataForm[prop].elementType === 'datepicker') {
      datePickerNames.push(dataForm[prop].elementConfig.name);
    }
  }

  const submitHandler = e => {
    // e.preventDefault();
    setIsLoading(true);
    const dataToSend = {};
    for (const property in formData) {
      if (property !== 'file') {
        dataToSend[property] =
          property !== 'photo_date' ? formData[property].value : formData[property].valuetosend;
      }
    }

    var data = new FormData();

    let fileArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].name === 'file') {
        fileArray.push(selectedFiles[i]);
      }
    }

    fileArray.forEach(function (element) {
      if (fileArray[fileArray.length - 1] === element) {
        data.append(element.name, element.file);
        //dataToSend['photo_title'] = element.file.name?.split('.')?.[0];
      }
    });
    const ratePhotoData = JSON.parse(localStorage.getItem('ratePhotoData'));
    data.append('data', JSON.stringify({ ...dataToSend, ...ratePhotoData }));

    data.append('section', 'interaction');
    data.append('sid', sid);
    getDynamic(id, slug, data);

    /* customAxios
      .post(`game/${slug}/${id}/ratephoto`, data)
      .then(response => {
        // dispatch(getGame(id, slug)).then(() => {
        //   setIsLoading(false);
        // });
        // localStorage.removeItem('ratePhotoData');
        
      })
      .catch(error => {
        setIsLoading(false);
      }); */
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    onSetFormValid,
    imgPreviewUrl,
    districts,
    cities,
    provinces,
    zipcode,
    resetFormHandler,
    formIsValid,
    setFormValid,
  } = useForm(submitHandler, dataForm, datePickerNames, false, true);
  useEffect(() => {
    if (dataAction?.length > 0) {
      setTimeout(() => {
        onShowResult();
      }, 1500);
    }
  }, [dataAction]);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.elementType === 'inputfile' ? !immagine : inp.valid}
        readonly={inp.readonly}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={inp.value}
        changedDatePicker={date => datePickerHandler(date, inp.name)}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={() => {
          deleteFileHandler();
        }}
        setFormValid={setFormValid}
        imagePreviewUrl={imgPreviewUrl}
        inModal={true}
        radioInputs={inp.inputs}
        setImage={setImage}
        // setFormValid={onSetFormValid}
        formSetSelectedFiles={formSetSelectedFiles}
        resetFormHandler={resetFormHandler}
        districts={districts}
        concorso
        lang='it'
        {...inp}
      />
    );
  });
  return (
    <Wrapper>
      <Text color={text_color} className='mb-20'>
        {i18l?.gallery?.content}
      </Text>
      <Box>
        <Text align='center' color={text_color} className='mb-20'>
          {i18l?.gallery?.title}
        </Text>
        <div style={{ width: 'fit-content', margin: '0 auto' }}>{inputs}</div>
        {immagine && (
          <Flex justify='center' align='center' style={{ marginTop: '20px' }}>
            <Button
              onClick={e => formSubmitHandler(e)}
              active
              hover
              padding='0 50px'
              loading={loading}
              style={{ justifyContent: 'center' }}
            >
              Invia
            </Button>
          </Flex>
        )}
        {/*     {action ? (
          <Dynamic
            id={id}
            slug={slug}
            section={typeInteraction}
            action={props.action}
            sid={sid}
            {...props}
          />
        ) : null} */}
      </Box>
    </Wrapper>
  );
};

const DownloadRegulation = styled.div`
  height: 230px;
  position: relative;
  margin-top: 30px;
  padding: 15px;

  & > div {
    position: relative;
    z-index: 1;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default Ratephoto;
