import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Typography, Video, Image, Audio, Text } from '../../../../Ui';
import { respondTo } from '../../../../../theme/mixin';

const QuestionContainer = styled.div`
  margin-bottom: 16px;
  max-width: 520px;
  margin: auto;
  box-sizing: border-box;
`;
const MediaContainer = styled.div`
  ${props =>
    props.isVideo &&
    css`
      ${respondTo.xs`
            min-height: 205px;
        `}
      ${respondTo.xmed`
            min-height: 270px;
        `}
        ${respondTo.med`
            min-height: 290px;
        `}
        ${respondTo.sm`
            min-height: 290px;
        `}
        ${respondTo.md`
            min-height: 290px;
        `}
    `}
`;
const QuestionText = styled.div`
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 12px 26px;
  margin: 10px auto;
  font-size: 20px;
  ${respondTo.sm`
    padding: 16px 24px;
  `}
`;

const Question = props => {
  const {
    children,
    pause,
    media: { type, src, option },
  } = props;

  let media = null;

  switch (type) {
    case 'video':
      media = <Video pause={pause} option={option} />;
      break;
    case 'image':
      media = <Image src={src} />;
      break;
    case 'audio':
      media = <Audio src={src} pause={pause} />;
      break;
    default:
      media = media;
      break;
  }

  return (
    <QuestionContainer>
      <MediaContainer isVideo={type === 'video' ? true : false}>{media}</MediaContainer>
      <Text bold size={20} as='p' align='center' className='mb-20'>
        {children}
      </Text>
    </QuestionContainer>
  );
};

Question.propTypes = {
  children: PropTypes.any,
};

export default Question;
