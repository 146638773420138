import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';
import { Text } from '../../../Ui';

const PrizeWrapper = styled.div`
  // background-color: ${({ theme }) => theme.bg_grey_light};
  width: calc(100% - 30px);
  max-width: 370px;
  position: relative;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  //box-shadow: 0px 2px 10px #00000026;
  img {
    border-radius: 10px;
  }
`;

const Prize = props => {
  const {
    prize: { title, content, image },
    prize_image,
    text_color,
  } = props;
  return (
    <PrizeWrapper>
      {title && (
        <Text upper bold align='center' className='mb-10'>
          {title}
        </Text>
      )}
      {content && (
        <Text align='center' capit className='mb-10'>
          {content}
        </Text>
      )}
      <img src={image || prize_image} />
    </PrizeWrapper>
  );
};

export default Prize;
