import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Wrapper } from "../../../../Ui";
import { respondTo } from "../../../../../theme/mixin";
const BoardContainer = styled.div`
	padding: 16px 0;
	min-height: 230px;
	text-align: center;
	display: flex;
	justify-content: center;
	background: white;
	width: 100%;
	${respondTo.sm`
		padding: 32px 0;

	`}
`;

const Board = (props) => {
	const { children } = props;
	return (
		<BoardContainer>
			<Wrapper>{children}</Wrapper>
		</BoardContainer>
	);
};

Board.propTypes = {
	children: PropTypes.any,
};

export default Board;
